::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 8px;
}

::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #666;
}

::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: rgba(0, 0, 0, 0);
}

section {
    h1 {
        font-size: 2em;
    }
}

.main-layout {
    min-height: 100vh;
}
@header-height: 64px;

.main-header {
    background: rgba(255, 255, 255, 0.85);
    transition-property: background;
    backdrop-filter: blur(4px);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    padding: 0 12px;

    .logo img {
        max-height: 28px;
        max-width: 100%;
    }
}
.navbar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .logo {
        flex: 1;
        min-width: 0;
        overflow: hidden;
    }

    .center {
        flex: 1;
    }

    .action {
        padding: 0 12px;
        color: rgba(0, 0, 0, 0.65);
        cursor: pointer;
        &:hover {
            background: rgba(0, 0, 0, 0.025);
        }
    }

    .user-info {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        .ant-avatar {
            margin-right: 12px;
        }
    }
}

.user-menu {
    min-width: 140px;
}
.main-content-layout {
    margin-top: @header-height;
    position: relative;
}

.main-sider {
    z-index: 98;
    position: fixed;
    top: @header-height;
    left: 0;
    bottom: 0;
    user-select: none;
    box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
}

.main-sider .ant-layout-sider-children {
    overflow: auto;
}

.ant-page-header {
    border-bottom: 1px solid #e8e8e8;
}

.main-content {
    margin-left: 256px;
}
.ant-layout-sider-below + .main-content {
    margin-left: 0;
}
body.drawer-opened {
    overflow: hidden;
}
.drawer-host {
    position: fixed;
    width: 100%;
    height: calc(100vh - @header-height);
    top: @header-height;
    left: 0;
    pointer-events: none;
    z-index: 10000;
    .ant-drawer {
        pointer-events: all;
    }
    .ant-drawer-mask {
        position: fixed;
        background: transparent;

        &::after {
            content: "";
            background: rgba(0, 0, 0, 0.45);
            position: absolute;
            top: @header-height;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
    .ant-drawer-content-wrapper {
        max-width: 100%;
    }
}

@media screen and (max-device-width: 414px) {
    .navbar .user-info {
        .ant-avatar {
            margin-right: 0;
        }
        .nickname {
            display: none;
        }
    }
}
